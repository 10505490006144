import React from "react"
import PageTransition from "gatsby-v2-plugin-page-transitions"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ProjectsNavigation from "../../components/projects-navigation"
import ProjectLogo from "../../components/project-logo"
import { content } from '../pages.module.scss'

export default function ProjektLogo() {
  const currentLang = "en"
  return (
    <PageTransition>
      <Layout lang={currentLang}>
        <Seo
          lang={currentLang}
          title="Project - Logo"
          keywords={[`powszuk`, `design`]}
        />
        <div className={content}>
          <ProjectLogo lang={currentLang} />
        </div>
        <ProjectsNavigation lang={currentLang} project="logo" />
      </Layout>
    </PageTransition>
  )
}
